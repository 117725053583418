<template>
    <v-dialog v-model="dialog"
                persistent
                dark
                max-width="600px">  
        <v-card>
            <v-card-title>
                <span class="text-h5">30 Day Premium Access</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" style="color:white;">
                            Purchase Premium Access - Total Cost: $8.00
                        </v-col>
                    </v-row>
                    <br />
                    <div v-if="isLoading" style="text-align:center;">
                        <font-awesome-icon class="fa-5x" :icon="['fa', 'spinner']" spin />
                        <br />
                    </div>
                    <div id="card-container"></div>
                </v-container>
                <div style="color:red; height:20px; font-weight:bold;">{{error}}</div>
                <hr style="border-color:white; border-style:solid; border-width:1px;" />
                <img style="width:100%; border-radius:10px;" src="../assets/square/Square_4Cards.png" />
                <span style="color:white;"><font-awesome-icon :icon="['fas', 'shield-alt']" color="white" class="ml-1 mr-2 mt-3" />Secure Payment Processing</span>  
            </v-card-text>
          
            <v-card-actions>
                <span class="ml-3 small" style="color:lightgray">
                    Single (non-recurring) payment
                </span>
                <v-spacer></v-spacer>

                <v-btn color="blue darken-1" text @click="closeForm()"> Cancel </v-btn>
                <v-btn v-if="enablePurchaseButton" :disabled="isPurchasing" color="red" text @click="purchase()">
                    <span v-if="!isPurchasing">Purchase</span>
                    <span v-if="isPurchasing">Purchasing</span>
                    <b-spinner class="ml-2" v-if="isPurchasing" small></b-spinner>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import { mapActions } from 'vuex';
    import { notificationHooks } from '../shared/vue-mixin';
    import { paymentService } from '../services/payment-service';
    import { userService } from '../services/user-service';
    import { serviceHelper } from '../shared/service-helper';
    import { SQUARE_LOCATION_ID, SQUARE_APPLICATION_ID } from '../shared/config';

    export default {
        name: 'PaymentForm',

        /* COMPONENT DATA  */
        data() {
            return {
                dialog: false,
                error: null,
                payments: null,
                card: null,
                isLoading: false,
                isPurchasing: false,
                hasSquareError: false,
            };
        },

        /* LIFE CYCLE METHODS  */
        async mounted() {

            if (!window.Square) {
                throw new Error('Square.js failed to load properly');
            }
        },

        /* MIXINS */
        mixins: [notificationHooks],

        /* COMPONENT METHODS  */
        methods: {
            ...mapActions(['setRefreshTokenAction', 'logoutUserAction']),

            async logout() {
                await this.logoutUserAction();
                router.push({ name: 'home' }).catch(err => { });
            },

            async initializeCard(payments) {

                const darkModeCardStyle = {
                    '.input-container': {
                        borderColor: '#2D2D2D',
                        borderRadius: '6px',
                    },
                    '.input-container.is-focus': {
                        borderColor: '#006AFF',
                    },
                    '.input-container.is-error': {
                        borderColor: '#ff1600',
                    },
                    '.message-text': {
                        color: '#999999',
                    },
                    '.message-icon': {
                        color: '#999999',
                    },
                    '.message-text.is-error': {
                        color: '#ff1600',
                    },
                    '.message-icon.is-error': {
                        color: '#ff1600',
                    },
                    input: {
                        backgroundColor: '#2D2D2D',
                        color: '#FFFFFF',
                        fontFamily: 'helvetica neue, sans-serif',
                    },
                    'input::placeholder': {
                        color: '#999999',
                    },
                    'input.is-error': {
                        color: '#ff1600',
                    },
                };

                const card = await payments.card({
                    style: darkModeCardStyle,
                });

                this.isLoading = false;

                //Clear credit card payment node
                const paymentNode = document.getElementById("card-container");
                paymentNode.textContent = '';

                await card.attach('#card-container');

                /* Clear out the error message if the user attempts to correct it */
                const callback = async (cardInputEvent) => {
                    this.error = null;
                };
                card.addEventListener("focusClassAdded", callback);
                card.addEventListener("focusClassRemoved", callback);

                //Don't allow the purchase button to be clicked if a Square error exists
                const addErrorCallback = async (cardInputEvent) => {
                    this.hasSquareError = true;
                };
                card.addEventListener("errorClassAdded", addErrorCallback);

                //Remove square errors once the user has fixed them
                const removeErrorCallback = async (cardInputEvent) => {
                    this.hasSquareError = false;
                };
                card.addEventListener("errorClassRemoved", removeErrorCallback);

                //Auto set focus to the cardNumber after initializing
                card.focus("cardNumber");

                return card;
            },

            async tokenize(paymentMethod) {
                const tokenResult = await paymentMethod.tokenize();
                if (tokenResult.status === 'OK') {
                    this.hasSquareError = false;
                    return tokenResult.token;
                } else {
                    this.hasSquareError = true;
                    let errorMessage = "";
                    if (tokenResult.errors) {
                        tokenResult.errors.forEach(
                            error => {
                                errorMessage += error.message + ", ";
                            });
                    }
                    errorMessage = errorMessage.replace(/,\s*$/, "");
                    throw new Error(errorMessage);
                }
            },

            async purchase() {

                this.error = null;
                this.isPurchasing = true;
                await serviceHelper.simulateAwait(true, 2500);
                try {
                    const token = await this.tokenize(this.card);
                    var paymentArgs = { paymentSource: token };
                    var result = await paymentService.purchasePremiumAccess(paymentArgs);
                    this.isPurchasing = false;
                    if (result.success) {

                        this.showSuccessMessage("CryptoTaxSpace", "Thank you for purchasing a CryptoTaxSpace premium membership.");

                        //Refresh user token which will update premium membership role
                        var tokenResponse = await userService.refreshToken();

                        if (tokenResponse.success) {
                            await this.setRefreshTokenAction(tokenResponse.value); //Call store action
                            this.closeForm();
                        }
                        else {
                            //Unlikely error happened, automatically sign user out
                            this.card.destroy();
                            this.showErrorMessage("CryptoTaxSpace", "An unknown error occurred, please sign-in again");
                            await this.logout();
                        }
                    }
                    else {
                        //Payment failed, show the error message to user on the payment form
                        this.error = result.error.replace(/^"(.*)"$/, '$1');
                        this.isPurchasing = false;
                    }
                } catch (error) {      
                    //Tokenize failed
                    this.error = error.message;
                    this.isPurchasing = false;
                }
            },

            closeForm() {
                try {
                    this.card.destroy();
                }
                catch (error) {
                }
                finally {
                    this.dialog = false;
                }      
            },

            /* PUBLIC METHODS  */
            async ShowModal() {
                this.isLoading = true;
                this.error = null;
                this.hasSquareError = false;
                this.dialog = true;

                try {
                    this.payments = window.Square.payments(SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID);
                } catch {
                    //Couldn't initialize payments
                    this.error = "We are experiencing technical difficulties, please try again later";
                    this.showErrorMessage("CryptoTaxSpace", this.error);
                    this.isLoading = false;
                    return;
                }

                try {
                    this.card = await this.initializeCard(this.payments);
                } catch (e) {
                    //Couldn't initialize Square form
                    this.error = "We are experiencing technical difficulties, please try again later";
                    this.showErrorMessage("CryptoTaxSpace", this.error);
                    this.isLoading = false;
                    return;
                }
            }
        },

        /* COMPUTED PROPERTIES */
        computed: {

            enablePurchaseButton() {
                if (this.error === null && this.hasSquareError === false) return true; else return false;
            },
        }
    };
</script>