import { API } from '../shared/config';
import { HTTP_POST  } from '../shared/constants';
import { serviceHelper } from '../shared/service-helper';

const purchasePremiumAccess = async function (paymentArgs) {
    try {
        const response = await serviceHelper.callApi(`${API}/PurchasePremiumAccess`, HTTP_POST, paymentArgs);
        return response;
    } catch (error) {
        return null;
    }
}

export const paymentService = {
    purchasePremiumAccess
};

